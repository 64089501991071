import axios from 'axios'
// import { Decode } from '@/services'

export default {
    async EditForm(val) {
        const response = await axios.get(
            `${process.env.VUE_APP_API}/form/getForm/` + val.id
        );
        console.log("response11", response.data.data);
        return response
    },
    // createForm() {
    //     this.$router.push("CreateForm");
    // },
}
