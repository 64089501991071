import Vue from 'vue'
import Vuex from 'vuex'
import AxiosAPI from '@/store/ModuleApi'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    stateEditForm: []
  },
  mutations: {
    mutationEditForm(state, data) {
      state.stateEditForm = data
    },
  },
  actions: {
    async actionsEditForm(context, access) {
      const axiosEditForm = await AxiosAPI.EditForm(access)
      await context.commit('mutationEditForm', axiosEditForm)
    },
  },
  modules: {
  
  }
})
// export default ModuleApi
